@import '../abstracts/variables';
.tax {
  display: block;
  &-hidden {
    display: none;
  }
}
*,
::after,
::before {
  box-sizing: border-box;
}
body {
  font-family: 'Roboto', sans-serif;
}

.collapse {
  display: block;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  &.show {
    max-height: 999em;
    transition: max-height 0.5s ease-in-out;
  }
}

#page-top header.masterhead {
  position: relative;
  .masthead-heading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .js-scroll-trigger {
    position: absolute;
    bottom: 0;
  }
}
#services {
  position: relative;
  &::after {
    content: '';
    background-image: url('data:image/svg+xml,<svg width="1em" height="1em" fill="#6d767e" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" transform="rotate(90)"><path d="M15.7 11.3l-6-6c-0.4-0.4-1-0.4-1.4 0s-0.4 1 0 1.4l5.3 5.3-5.3 5.3c-0.4 0.4-0.4 1 0 1.4 0.2 0.2 0.4 0.3 0.7 0.3s0.5-0.1 0.7-0.3l6-6c0.4-0.4 0.4-1 0-1.4z"></path></svg>');
    background-size: 100px 100px;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    width: 100px;
    height: 100px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  &.show-chevron::after {
    opacity: 1;
  }
  #services-row {
    $color-1: $colour-spread-4;
    & > :nth-child(1) {
      background-color: $color-1;
      color: getVisibleTextColor($color-1);
    }
    & > :nth-child(2) {
      $color-2: $color-1;
      // $color-2: $palette-colour2;

      background-color: $color-2;
      color: getVisibleTextColor($color-2);
    }
    & > :nth-child(3) {
      $color-3: $color-1;
      // $color-3: $palette-colour3;

      background-color: $color-3;
      color: getVisibleTextColor($color-3);
    }
    .selectable-category {
      border: 1px solid #dcdcdc;
      border-radius: 0;
      padding-top: 20px;
      //   border: 1px solid red;
      cursor: pointer;

      &:hover {
        box-shadow: 6px 3px 15px 0 rgba(0, 0, 0, 0.1);
        color: getVisibleTextColor(hsl(0, 0%, 100%));
      }
      &.active {
        box-shadow: 6px 3px 15px 0 rgba(0, 0, 0, 0.2);
        background-color: hsl(208, 7%, 46%);
        color: getVisibleTextColor(hsl(208, 7%, 46%));
      }
      @media (min-width: 768px) {
        max-width: 30%;
      }
      max-width: 90%;
      &:not(:last-child) {
        @media (max-width: 767px) {
          margin-bottom: 10px;
        }
      }
      .card-text {
        font-weight: 300;
        margin-bottom: 4rem;
      }
      .card-btn-style {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        font-size: small;
        min-width: 60%;
        max-width: 80%;
      }
      &.active .card-btn-style:hover {
        color: #6d767e;
      }
    }
  }
  // .box-shadow-light {
  //   box-shadow: 6px 3px 15px 0 rgb(0 0 0 / 10%);
  // }
  .overlay {
    position: absolute;
    top: 0;
    background-color: rgba(142, 188, 206, 0.8);
    width: 100%;
    height: 100%;
    z-index: 2;
    border-radius: 0.3rem;
  }
  .bg-img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('/assets/images/portfolio/02-full.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    // padding-top: 20px;
    margin-bottom: 0;
    z-index: 1;
    border-radius: 0.3rem;
    &.confeccion_planos {
      background-image: url('/assets/images/modals/confeccion_planos.jpeg');
    }
    &.levantamiento {
      background-image: url('/assets/images/modals/levantamiento.jpg');
    }
    &.sec {
      background-image: url('/assets/images/modals/sec.png');
    }
    &.iluminacion {
      background-image: url('/assets/images/modals/iluminacion.jpg');
    }
    &.integracion {
      background-image: url('/assets/images/modals/integracion.jpg');
    }
    &.mantenimiento_motores {
      background-image: url('/assets/images/modals/mantenimiento_motores.jpg');
    }
    &.mantenimiento_tableros {
      background-image: url('/assets/images/modals/mantenimiento_tableros.jpeg');
    }
    &.mantenimiento_maquinaria {
      background-image: url('/assets/images/modals/mantenimiento_maquinaria.jpg');
    }
    &.diseno_maquinas {
      background-image: url('/assets/images/modals/diseno_maquinas.jpeg');
    }
    &.tierra {
      background-image: url('/assets/images/modals/tierra.jpg');
    }
  }
}
.overlay {
  position: absolute;
  top: 0;
  background-color: rgba(142, 188, 206, 0.8);
  width: 100%;
  height: 100%;
  z-index: 2;
  border-radius: 0.3rem;
}
#globalModal {
  .modal-content {
    .modal-header {
      text-align: center;
      background-color: #f2f2f2;
      padding: 1rem 3rem 1rem 1rem;
      h2 {
        font-size: calc(20px + 1.5vw);
        font-size: clamp(25px, 2.5vw, 30px);
        margin-left: 1rem;
      }
      .close-modal {
        position: absolute;
        right: 1rem;
        top: 1rem;
        img {
          margin: 0;
        }
      }
    }
    .modal-body {
      text-align: start;
      padding: 2rem;
      #globalModalText {
        margin: 0;
      }
    }
  }
}
.cta-read-more {
  // color: red;
  // margin-top: 1rem;
  padding: 0.5 2rem;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%);
  min-width: 50%;
}
.portfolio-item {
  .portfolio-caption {
    position: relative;
  }
}
